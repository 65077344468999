/* eslint-disable jsx-a11y/anchor-has-content */
import { useCallback, useEffect, useRef, useState } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import $ from "jquery";
// import LineComp from "../Icon";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { EffectFade, Mousewheel, Pagination } from "swiper";
import "swiper/swiper-bundle.css";
// Import Swiper styles
import "swiper/swiper.scss"; // core Swiper
import "swiper/modules/effect-fade/effect-fade.scss";
import "swiper/modules/navigation/navigation.scss"; // Navigation module
import "swiper/modules/pagination/pagination.scss"; // Pa
import "./index.scss";
import LogoComp from "../Icon/logo";
SwiperCore.use([EffectFade, Pagination]);

const NewMobileHome = (): JSX.Element => {
  const [swiperRef, setSwiperRef] = useState<any>(null);
  const goToPage = (type: any) => {
    if (type === 0) {
      $(".header").show();
    } else {
      $(".header").hide();
    }
    swiperRef.slideTo(type, 100);
  };
  const [navBtnMobile, setNavBtnClassNamesMobile] = useState("iconUl menu");
  // const [navModelMobile, setNavModelClassNamesMobile] = useState('nav-model');

  const [anchorElMobile, setAnchorElMobile] = useState<null | HTMLElement>(
    null
  );
  const openMobile = Boolean(anchorElMobile);

  const handleMenuClickMobile = (event: React.MouseEvent<HTMLElement>) => {
    const isActive = navBtnMobile.indexOf("active");
    if (isActive < 0) {
      setNavBtnClassNamesMobile("iconUl menu active opened");
      // setNavModelClassNamesMobile('nav-model active');
      document.getElementsByTagName("body")[0].classList.add("overFlow");
    } else {
      setNavBtnClassNamesMobile("iconUl menu");
      // setNavModelClassNamesMobile('nav-model');
      document.getElementsByTagName("body")[0].classList.remove("overFlow");
    }
    setAnchorElMobile(event.currentTarget);
  };
  const handleMenuCloseMobile = () => {
    const isActive = navBtnMobile.indexOf("active");
    if (isActive < 0) {
      setNavBtnClassNamesMobile("iconUl menu active opened");
      // setNavModelClassNamesMobile('nav-model active');
      document.getElementsByTagName("body")[0].classList.add("overFlow");
    } else {
      setNavBtnClassNamesMobile("iconUl menu");
      // setNavModelClassNamesMobile('nav-model');
      document.getElementsByTagName("body")[0].classList.remove("overFlow");
    }
    setAnchorElMobile(null);
  };
  const changeNavStatusMobile = (event: React.MouseEvent<HTMLElement>) => {
    const isActive = navBtnMobile.indexOf("active");
    if (isActive < 0) {
      setNavBtnClassNamesMobile("iconUl menu active opened");
      // setNavModelClassNamesMobile('nav-model active');
      document.getElementsByTagName("body")[0].classList.add("overFlow");
      handleMenuClickMobile(event);
    } else {
      setNavBtnClassNamesMobile("iconUl menu");
      // setNavModelClassNamesMobile('nav-model');
      document.getElementsByTagName("body")[0].classList.remove("overFlow");
      handleMenuCloseMobile();
    }
  };
  let currentTop = useRef(0).current;
  let [isScroll, setIsScroll] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", windheaderScroll);
  });

  const windheaderScroll = useCallback(
    (event: any) => {
      console.log("%c🀁 ", "color: #733d00; font-size: 20px;", 112211);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const oldScrollTop = currentTop;
      // console.log(currentTop)
      const scrollTop =
        (event.srcElement
          ? event.srcElement.documentElement.scrollTop
          : false) ||
        window.pageYOffset ||
        (event.srcElement ? event.srcElement.body.scrollTop : 0);
      if (scrollTop <= 200) {
        setIsScroll(false);
      } else {
        setIsScroll(true);
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
      currentTop = scrollTop;
    },
    [currentTop]
  );

  // const downLoadIOS = () => {
  //   window.open('https://apps.apple.com/us/app/crushe-more-than-a-crush/id1641227122')
  // }

  const jumptUrl = (type: string) => {
    switch (type) {
      case "ios":
        window.open(
          "https://apps.apple.com/us/app/crushe-more-than-a-crush/id1641227122"
        );
        break;
      case "google":
        window.open(
          "https://play.google.com/store/apps/details?id=xyz.nbrlabs.riel"
        );
        break;
      case "test":
        window.open("https://testflight.apple.com/join/IUZOlC5J");
        break;
      case "android":
        window.open(
          "https://prod-nxglabs.s3.amazonaws.com/resources/crushe_1227_release_649_v1.4.1.apk"
        );
        break;
    }
  };
  return (
    <div>
      <div className="mobileHome">
        {/* style={{display: swiperRef.activeIndex === 0 ? "block" : "none"}} */}
        <div className={["header", isScroll ? "scroll" : ""].join(" ")}>
          <div className="content">
            <div className="logo">
              <LogoComp></LogoComp>
            </div>
            <div
              className="header-menu-mobile icon"
              style={{
                position: "absolute",
                top: ".12rem",
                left: ".21rem",
                zIndex: 1400,
              }}
            >
              <button
                className={navBtnMobile}
                aria-label="Main Menu"
                onClick={changeNavStatusMobile}
              >
                <svg width={36} height={36} viewBox="0 0 100 100">
                  <path
                    className="line line1"
                    d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"
                  />
                  <path className="line line2" d="M 20,50 H 80" />
                  <path
                    className="line line3"
                    d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
        <Swiper
          direction={"vertical"}
          slidesPerView={1}
          mousewheel={true}
          pagination={{
            clickable: true,
          }}
          initialSlide={0}
          modules={[EffectFade, Mousewheel, Pagination]}
          effect={"fade"}
          className="phoneSwiper"
          onSwiper={(swiper: any) => {
            setSwiperRef(swiper);
          }}
          onSlideChangeTransitionStart={(swiper: any) => {
            // console.log(swiperRef);
            if (swiperRef.activeIndex > 0) {
              $(".header").hide();
            }
            $(".card").hide();
            if ($(".card").hasClass("aos-animate")) {
              $(".card").removeClass("aos-animate");
            }
          }}
          onSlideChangeTransitionEnd={() => {
            if (swiperRef.activeIndex === 0) {
              $(".header").show();
            } else {
              $(".header").hide();
            }
            $(".card").show();
            $(".card").addClass("aos-animate");
            Aos.init({
              anchorPlacement: "center-center",
              mirror: true,
              easing: "ease",
              offset: 0,
              duration: 1000,
            });
          }}
        >
          <SwiperSlide>
            <div className="banner">
              <h1
                className="title card"
                data-aos="fade-up"
                data-aos-offset="200"
                data-aos-delay="50"
                data-aos-duration="1000"
                data-aos-easing="ease-in-out"
                data-aos-mirror="true"
              >
                A Web3 Social Metaverse For The Next Generations
              </h1>
              <div
                className="tips card"
                data-aos="fade-up"
                data-aos-offset="-200"
                data-aos-delay="50"
                data-aos-duration="1000"
                data-aos-easing="ease-in-out"
                data-aos-mirror="true"
              >
                Share / Meet / Connect / Build
              </div>
              <div className="btn_box">
                <div
                  className="btn_group card"
                  data-aos="fade-up"
                  data-aos-offset="-500"
                  data-aos-delay="250"
                  data-aos-duration="1000"
                  data-aos-easing="ease-in-out"
                  data-aos-mirror="true"
                >
                  <button onClick={() => jumptUrl("ios")}>
                    <img
                      src={
                        require("../../assets/newimg/iphone_logo.png").default
                      }
                      alt=""
                    />
                    <div className="text">
                      <h6 className="name">App Store</h6>
                    </div>
                  </button>
                  <button onClick={() => jumptUrl("google")}>
                    <img
                      src={
                        require("../../assets/newimg/google_logo.png").default
                      }
                      alt=""
                    />
                    <div className="text">
                      <h6 className="name">Google Play</h6>
                    </div>
                  </button>
                </div>
                <div
                  className="btn_group card"
                  data-aos="fade-up"
                  data-aos-offset="-500"
                  data-aos-delay="250"
                  data-aos-duration="1000"
                  data-aos-easing="ease-in-out"
                  data-aos-mirror="true"
                >
                  <button onClick={() => jumptUrl("test")}>
                    <img
                      src={require("../../assets/newimg/test_logo.png").default}
                      alt=""
                    />
                    <div className="text">
                      <h6 className="name">IOS Testflight</h6>
                    </div>
                  </button>
                  <button onClick={() => jumptUrl("android")}>
                    <img
                      src={
                        require("../../assets/newimg/andriod_logo.png").default
                      }
                      alt=""
                    />
                    <div className="text">
                      <h6 className="name">Android APK</h6>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="self">
              <div className="top">
                <div className="phone">
                  <img
                    src={require("../../assets/home/bg.png").default}
                    alt=""
                    width={"100%"}
                  />
                  <img
                    className="contentImg"
                    src={require("../../assets/home/phone.png").default}
                    alt=""
                    width={"100%"}
                    // data-aos="zoom-in-out"
                  />
                </div>
                <div
                  className="top-avatar1 card"
                  data-aos="zoom-in-up"
                  data-aos-offset="200"
                  data-aos-delay="250"
                  data-aos-duration="1500"
                  data-aos-easing="ease-in-out"
                  data-aos-mirror="true"
                  data-aos-anchor-placement="bottom-bottom"
                >
                  <img
                    src={require("../../assets/home/hudie.png").default}
                    alt=""
                    width={"100%"}
                  />
                </div>
                <div
                  className="top-avatar3 card"
                  data-aos="zoom-in-up"
                  data-aos-offset="200"
                  data-aos-delay="60"
                  data-aos-duration="1500"
                  data-aos-easing="ease-in-out"
                  data-aos-mirror="true"
                  data-aos-anchor-placement="bottom-bottom"
                >
                  <img
                    src={require("../../assets/home/xing.png").default}
                    alt=""
                    width={"100%"}
                  />
                </div>
                <div
                  className="top-avatar2 card"
                  data-aos="zoom-in-up"
                  data-aos-offset="200"
                  data-aos-delay="50"
                  data-aos-duration="1000"
                  data-aos-easing="ease-in-out"
                  data-aos-mirror="true"
                  data-aos-anchor-placement="bottom-bottom"
                >
                  <img
                    src={require("../../assets/home/face.png").default}
                    alt=""
                    width={"100%"}
                  />
                </div>
              </div>
              <div className="text">
                <h1
                  className="title card"
                  data-aos="fade-up"
                  data-aos-offset="200"
                  data-aos-delay="50"
                  data-aos-duration="1000"
                  data-aos-easing="ease-in-out"
                  data-aos-mirror="true"
                  data-aos-anchor-placement="top-center"
                >
                  Know yourself
                </h1>
                <p
                  className="card"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-easing="ease-in-out"
                  data-aos-mirror="true"
                  data-aos-anchor-placement="top-center"
                >
                  Do a "soul test", and get your web3 "soulbound token" (SBT).
                  It reveals a lot about who you are and what you love.
                </p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="life">
              <div className="right">
                <div className="phone">
                  <img
                    src={require("../../assets/home/bg.png").default}
                    alt=""
                    width={"100%"}
                  />
                  <img
                    className="contentImg"
                    src={require("../../assets/home/crushphone.png").default}
                    alt=""
                    width={"100%"}
                    // data-aos="zoom-in-out"
                  />
                </div>
              </div>
              <div className="text">
                <h1
                  className="title card"
                  data-aos="fade-up"
                  data-aos-offset="200"
                  data-aos-delay="50"
                  data-aos-duration="1000"
                  data-aos-easing="ease-in-out"
                  data-aos-mirror="true"
                  data-aos-anchor-placement="bottom-bottom"
                >
                  Soul-based <br /> algorithm
                </h1>
                <p
                  className="card"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-easing="ease-in-out"
                  data-aos-mirror="true"
                  data-aos-anchor-placement="top-center"
                >
                  We recommend people that get your personality traits and share
                  your interest through our SBT matching system.{" "}
                </p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="swiping">
              <div className="top">
                <div className="phone">
                  <img
                    src={require("../../assets/home/bg.png").default}
                    alt=""
                    width={"100%"}
                  />
                  <img
                    className="contentImg"
                    src={require("../../assets/home/swipingPhone.png").default}
                    alt=""
                    width={"100%"}
                    // data-aos="zoom-in-out"
                  />
                </div>
                <div
                  className="top-avatar1 card"
                  data-aos="zoom-in-up"
                  data-aos-offset="200"
                  data-aos-delay="250"
                  data-aos-duration="1500"
                  data-aos-easing="ease-in-out"
                  data-aos-mirror="true"
                  data-aos-anchor-placement="bottom-bottom"
                >
                  <img
                    src={require("../../assets/home/aixin.png").default}
                    alt=""
                    width={"100%"}
                  />
                </div>
                <div
                  className="top-avatar3 card"
                  data-aos="zoom-in-up"
                  data-aos-offset="200"
                  data-aos-delay="60"
                  data-aos-duration="1500"
                  data-aos-easing="ease-in-out"
                  data-aos-mirror="true"
                  data-aos-anchor-placement="bottom-bottom"
                >
                  <img
                    src={require("../../assets/home/huaxue.png").default}
                    alt=""
                    width={"100%"}
                  />
                </div>
                <div
                  className="top-avatar2 card"
                  data-aos="zoom-in-up"
                  data-aos-offset="-200"
                  data-aos-delay="50"
                  data-aos-duration="1000"
                  data-aos-easing="ease-in-out"
                  data-aos-mirror="true"
                  data-aos-anchor-placement="bottom-bottom"
                >
                  <img
                    src={require("../../assets/home/tag.png").default}
                    alt=""
                    width={"100%"}
                  />
                </div>
              </div>
              <div className="text">
                <h1
                  className="title card"
                  data-aos="fade-up"
                  data-aos-offset="200"
                  data-aos-delay="50"
                  data-aos-duration="1000"
                  data-aos-easing="ease-in-out"
                  data-aos-mirror="true"
                  data-aos-anchor-placement="top-center"
                >
                  No more swiping
                </h1>
                <p
                  className="card"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-easing="ease-in-out"
                  data-aos-mirror="true"
                  data-aos-anchor-placement="top-center"
                >
                  Explore and jump into anyone's universe right away! Find your
                  real crush and connect instantly.
                </p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="game">
              <div className="top">
                <div className="phone">
                  <img
                    src={require("../../assets/home/bg.png").default}
                    alt=""
                    width={"100%"}
                  />
                  <img
                    className="contentImg"
                    src={require("../../assets/home/gamePhone.png").default}
                    alt=""
                    width={"100%"}
                    // data-aos="zoom-in-out"
                  />
                </div>
              </div>
              <div className="text">
                <h1
                  className="title card"
                  data-aos="fade-up"
                  data-aos-offset="200"
                  data-aos-delay="50"
                  data-aos-duration="1000"
                  data-aos-easing="ease-in-out"
                  data-aos-mirror="true"
                  data-aos-anchor-placement="bottom-bottom"
                >
                  Gamified experience
                </h1>
                <p
                  className="card"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-easing="ease-in-out"
                  data-aos-mirror="true"
                  data-aos-anchor-placement="top-center"
                >
                  There are sooo many ways to meet new people: mysterious
                  phonecalls, virtual parties, anonymous meetups, NFT breeding
                  games... you name it!
                </p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="yourself">
              <div className="top">
                <div className="phone">
                  <img
                    className="card"
                    src={require("../../assets/home/yourselfPhone.png").default}
                    alt=""
                    width={"100%"}
                    // data-aos="zoom-in-out"
                  />
                </div>
                <div
                  className="top-avatar1 card"
                  data-aos="zoom-in-up"
                  data-aos-offset="200"
                  data-aos-delay="60"
                  data-aos-duration="1500"
                  data-aos-easing="ease-in-out"
                  data-aos-mirror="true"
                  data-aos-anchor-placement="bottom-bottom"
                >
                  <img
                    src={require("../../assets/home/tangguo.png").default}
                    alt=""
                    width={"100%"}
                  />
                </div>
                <div
                  className="top-avatar3 card"
                  data-aos="zoom-in-up"
                  data-aos-offset="-200"
                  data-aos-delay="50"
                  data-aos-duration="1000"
                  data-aos-easing="ease-in-out"
                  data-aos-mirror="true"
                  data-aos-anchor-placement="bottom-bottom"
                >
                  <img
                    src={require("../../assets/home/xingxing.png").default}
                    alt=""
                    width={"100%"}
                  />
                </div>
                <div
                  className="top-avatar2 card"
                  data-aos="zoom-in-up"
                  data-aos-offset="200"
                  data-aos-delay="250"
                  data-aos-duration="1500"
                  data-aos-easing="ease-in-out"
                  data-aos-mirror="true"
                  data-aos-anchor-placement="bottom-bottom"
                >
                  <img
                    src={require("../../assets/home/xingai.png").default}
                    alt=""
                    width={"100%"}
                  />
                </div>
              </div>
              <div className="text">
                <h1
                  className="title card"
                  data-aos="fade-up"
                  data-aos-offset="200"
                  data-aos-delay="50"
                  data-aos-duration="1000"
                  data-aos-easing="ease-in-out"
                  data-aos-mirror="true"
                  data-aos-anchor-placement="top-center"
                >
                  Be yourself, and find the like-minded
                </h1>
                <p
                  className="card"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-easing="ease-in-out"
                  data-aos-mirror="true"
                  data-aos-anchor-placement="top-center"
                >
                  Tired of being a poser on social media? Share your real
                  lifestyle here anonymously and safely. That soul who really
                  gets you will come around!
                </p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="nft">
              <div className="content" id="NFT">
                <div className="right">
                  <div className="img_box">
                    <div className="card" data-aos="zoom-in-out">
                      <img
                        src={require("../../assets/home/NFT.png").default}
                        alt=""
                        width={"100%"}
                      />
                    </div>
                  </div>
                </div>
                <div className="left">
                  <div
                    className="title card"
                    data-aos="fade-up"
                    data-aos-offset="200"
                    data-aos-delay="50"
                    data-aos-duration="1000"
                    data-aos-easing="ease-in-out"
                    data-aos-mirror="true"
                    data-aos-anchor-placement="bottom-bottom"
                  >
                    <div>Find your Crushe NFT</div>
                    <div className="second">Get your Crushe superpower </div>
                    <div>Be yourself </div>
                  </div>
                  <ul className="list">
                    <div
                      className="item card"
                      data-aos="fade-up"
                      data-aos-duration="1000"
                      data-aos-easing="ease-in-out"
                      data-aos-mirror="true"
                      data-aos-anchor-placement="top-center"
                    >
                      <p>
                        {/* 
                        {" "} */}
                        Find the Crushe NFTs that match your soul the most.
                      </p>
                    </div>
                    <div
                      className="item card"
                      data-aos="fade-up"
                      data-aos-duration="1000"
                      data-aos-easing="ease-in-out"
                      data-aos-mirror="true"
                      data-aos-anchor-placement="top-center"
                    >
                      <p>
                        {/* {" "} */}
                        It speeds up your level in Crushe, and increases your
                        chance of getting free airdrops.
                      </p>
                    </div>
                    <div
                      className="item card"
                      data-aos="fade-up"
                      data-aos-duration="1000"
                      data-aos-easing="ease-in-out"
                      data-aos-mirror="true"
                      data-aos-anchor-placement="top-center"
                    >
                      <p>
                        If you find your soulmate in Crushe, you two can breed
                        your NFT offspring.
                        {/* {" "} */}
                      </p>
                    </div>
                  </ul>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="footer-box">
              <div className="token">
                <div className="content" id="Token">
                  <h1
                    className="title card"
                    data-aos="fade-up"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out"
                  >
                    Token
                  </h1>
                  <div
                    className="list card"
                    data-aos="fade-up"
                    data-aos-duration="800"
                    data-aos-easing="ease-in-out"
                  >
                    <div className="item item1">
                      <div className="coin_box">
                        <img
                          src={
                            require("../../assets/newimg/token1.png").default
                          }
                          alt=""
                          width={"100%"}
                        />
                      </div>
                      <p>Spark</p>
                    </div>
                    <div className="item item2">
                      <div className="coin_box">
                        <img
                          src={
                            require("../../assets/newimg/token2.png").default
                          }
                          alt=""
                          width={"100%"}
                        />
                      </div>
                      <p>Heart</p>
                    </div>
                    <div className="item item3">
                      <div className="coin_box">
                        <img
                          src={
                            require("../../assets/newimg/token3.png").default
                          }
                          alt=""
                          width={"100%"}
                        />
                      </div>
                      <p>Crushe</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="footer">
                <p className="email">Email: nxgentech2022@gmail.com</p>
                <p className="corpy">© 2022 NXG Labs. All rights reserved.</p>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
      <div className={["Menu-list", openMobile ? "open" : ""].join(" ")}>
        <div className="content">
          <div className="nav-top">
            <div
              className="header-nav-item"
              onClick={() => {
                goToPage(0);
              }}
              id="Overview nav-Overview"
            >
              <div className="item" onClick={changeNavStatusMobile}>
                <div>Overview</div>
              </div>
            </div>
            <div
              className="header-nav-item"
              onClick={() => {
                goToPage(1);
              }}
              id="Product nav-Product"
            >
              <div className="item" onClick={changeNavStatusMobile}>
                <div>Product</div>
              </div>
            </div>
            <div
              className="header-nav-item"
              onClick={() => {
                goToPage(6);
              }}
              id="NFT nav-NFT"
            >
              <div className="item" onClick={changeNavStatusMobile}>
                <div>NFT</div>
              </div>
            </div>
            <div
              className="header-nav-item"
              onClick={() => {
                goToPage(7);
              }}
              id="Token nav-Token"
            >
              <div className="item" onClick={changeNavStatusMobile}>
                <div>Token</div>
              </div>
            </div>
          </div>
          <div className="nav-bottom">
            <div className="item">
              <a href="/privacy" target={"_blank"}>
                Privacy policy
              </a>
            </div>
            <div className="item">
              <a href="/terms" target={"_blank"}>
                Terms of service
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewMobileHome;
