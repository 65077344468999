/*
 * @Author: ai.zhang
 * @Date: 2022-11-28 10:08:06
 * @LastEditors: ai.zhang
 * @Description: 
 */
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom'
import { ToastContainer } from 'react-toastify';
import './App.scss';
import 'react-toastify/dist/ReactToastify.css';
// import Home from './pages/Home';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';
// import NewHomePage from './pages/newHome';
// import HomePage from './pages/newHome1';
import ScrollMagic from 'scrollmagic';
import { useEffect, useState } from 'react';
// import { isMobile } from './helpers/utilities';
import NewMobileHome from './pages/newMobileHome2';
import SwiperDemo from './pages/swiperDemo';

function App() {
  const [isMobile, setIsMobile] = useState(false)
  useEffect(()=>{

    const availWidth = window.screen.availWidth
    if(availWidth < 750) {
      setIsMobile(true)
    }
    const controller = new ScrollMagic.Controller({ globalSceneOptions: { triggerHook: 0.5, loglevel: 1, }, });

    const app = document.getElementById('Product');
    console.log('%c🀅 app', 'color: #0088cc; font-size: 20px;', app);
    if(app){
      // @ts-ignore
      const scene1 = new ScrollMagic.Scene({ duration: app.clientHeight, triggerElement: '#Product' })
      .setClassToggle('#nav-Product', 'active') // add class toggle
      .addTo(controller);
      scene1.update(true);
      const toolkit = document.getElementById('NFT');
      // @ts-ignore
      new ScrollMagic.Scene({ duration: toolkit.clientHeight, triggerElement: '#NFT' })
        .setClassToggle('#nav-NFT', 'active') // add class toggle
        .addTo(controller);
      const roadmap = document.getElementById('Token');
      // @ts-ignore
      new ScrollMagic.Scene({ duration: roadmap.clientHeight, triggerElement: '#Token' })
        .setClassToggle('#nav-Token', 'active') // add class toggle
        .addTo(controller);

    }
  }, [])
  
  return (
    <Router>
      <Routes>
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/" element={isMobile?<NewMobileHome/>:<SwiperDemo />} />
        {/* <Route path="/demo" element={<SwiperDemo />} /> */}
      </Routes>
      <ToastContainer theme="dark" autoClose={3000} hideProgressBar />
    </Router>
  );
}

export default App;
