/*
 * @Author: ai.zhang
 * @Date: 2023-01-03 10:35:56
 * @LastEditors: ai.zhang
 * @Description:
 */
import { ReactNode, useCallback, useRef, useEffect, useState } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import $ from "jquery";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  EffectFade,
  Mousewheel,
  Pagination,
  Autoplay,
} from "swiper";
import "swiper/swiper-bundle.css";
// Import Swiper styles
import "swiper/swiper.scss"; // core Swiper
import "swiper/modules/navigation/navigation.scss"; // Navigation module
import "swiper/modules/pagination/pagination.scss"; // Pa
import "swiper/modules/effect-fade/effect-fade.scss";
import "./index.scss";

SwiperCore.use([EffectFade, Pagination, SwiperSlide]);

const SwiperDemo = (): JSX.Element => {
  const [swiperRef, setSwiperRef] = useState<any>(null);
  const jumptUrl = (type: string) => {
    switch (type) {
      case "ios":
        window.open(
          "https://apps.apple.com/us/app/crushe-more-than-a-crush/id1641227122"
        );
        break;
      case "google":
        window.open(
          "https://play.google.com/store/apps/details?id=xyz.nbrlabs.riel"
        );
        break;
      case "test":
        window.open("https://testflight.apple.com/join/IUZOlC5J");
        break;
      case "android":
        window.open(
          "https://prod-nxglabs.s3.amazonaws.com/resources/crushe_1227_release_649_v1.4.1.apk"
        );
        break;
    }
  };

  const goToPage = (type: any) => {
    // console.log(type);
    swiperRef.slideTo(type, 0);
  };
  // console.log(swiperRef.translate = "")
  useEffect(() => {
    Aos.init({
      anchorPlacement: "center-center",
      mirror: true,
      easing: "ease",
      offset: 0,
      duration: 1000,
    });
  }, []);

  return (
    <>
      <Swiper
        direction={"vertical"}
        slidesPerView={1}
        // spaceBetween={30}
        mousewheel={true}
        pagination={{
          clickable: true,
        }}
        fadeEffect={{
          crossFade: true,
        }}
        effect="fade"
        modules={[EffectFade, Mousewheel, Pagination]}
        className="mySwiper"
        // speed={600}
        onSwiper={(swiper: any) => {
          setSwiperRef(swiper);
        }}
        onSlideChangeTransitionStart={(swiper: any) => {
          $(".card").hide();
          if ($(".card").hasClass("aos-animate")) {
            $(".card").removeClass("aos-animate");
          }
        }}
        onSlideChangeTransitionEnd={() => {
          $(".card").show();
          $(".card").addClass("aos-animate");
          Aos.init({
            anchorPlacement: "center-center",
            mirror: true,
            easing: "ease",
            offset: 0,
            duration: 1000,
          });
        }}
      >
        <SwiperSlide>
          <div className="box" id="Overview">
            <div className="header">
              <div className="container">
                <div className="logo">
                  <img
                    src={require("../../assets/home/icon.png").default}
                    alt=""
                  />
                  <img
                    className="logoImgae"
                    src={require("../../assets/home/logo.png").default}
                    alt=""
                  />
                </div>
                <div className="space"></div>
                <ul className="header-nav">
                  <div
                    className="header-nav-item"
                    id="Overview"
                    onClick={() => {
                      goToPage(0);
                    }}
                  >
                    <div className="header-nav-item-title">Overview</div>
                    <span
                      className="header-nav-item-doll"
                      style={{ width: "90%", height: "0.05rem" }}
                    />
                  </div>
                  <div
                    className="header-nav-item"
                    id="Product nav-Product"
                    onClick={() => {
                      goToPage(1);
                    }}
                  >
                    <div className="header-nav-item-title">Product</div>
                    <span className="header-nav-item-doll" />
                  </div>
                  <div
                    className="header-nav-item"
                    id="NFT nav-NFT"
                    onClick={() => {
                      goToPage(6);
                    }}
                  >
                    <div className="header-nav-item-title">NFT</div>
                    <span className="header-nav-item-doll" />
                  </div>
                  <div
                    className="header-nav-item"
                    id="Token nav-Token"
                    onClick={() => {
                      goToPage(7);
                    }}
                  >
                    <div className="header-nav-item-title">Token</div>
                    <span className="header-nav-item-doll" />
                  </div>
                </ul>
              </div>
            </div>
            <div className="banner">
              <div className="content">
                <h1
                  className="title card"
                  data-aos="fade-up"
                  data-aos-offset="200"
                  data-aos-delay="50"
                  data-aos-duration="1000"
                  data-aos-easing="ease-in-out"
                  data-aos-mirror="true"
                >
                  A Web3 Social Metaverse For The Next Generations
                </h1>
                <div
                  className="tips card"
                  data-aos="fade-up"
                  data-aos-offset="200"
                  data-aos-delay="50"
                  data-aos-duration="1000"
                  data-aos-easing="ease-in-out"
                  data-aos-mirror="true"
                >
                  Share / Meet / Connect / Build
                </div>
                <div
                  className="btn_group group1 card"
                  data-aos="fade-up"
                  data-aos-offset="-200"
                  data-aos-delay="250"
                  data-aos-duration="1000"
                  data-aos-easing="ease-in-out"
                  data-aos-mirror="true"
                >
                  <button onClick={() => jumptUrl("ios")}>
                    <img
                      src={
                        require("../../assets/newimg/iphone_logo.png").default
                      }
                      alt=""
                    />
                    <div className="text">
                      <h6 className="name">App Store</h6>
                    </div>
                  </button>
                  <button onClick={() => jumptUrl("google")}>
                    <img
                      src={
                        require("../../assets/newimg/google_logo.png").default
                      }
                      alt=""
                    />
                    <div className="text">
                      <h6 className="name">Google Play</h6>
                    </div>
                  </button>
                </div>
                <div
                  className="btn_group card"
                  data-aos="fade-up"
                  data-aos-offset="-200"
                  data-aos-delay="250"
                  data-aos-duration="1000"
                  data-aos-easing="ease-in-out"
                  data-aos-mirror="true"
                >
                  <button onClick={() => jumptUrl("test")}>
                    <img
                      src={require("../../assets/newimg/test_logo.png").default}
                      alt=""
                    />
                    <div className="text">
                      <h6 className="name">IOS Testflight</h6>
                    </div>
                  </button>
                  <button onClick={() => jumptUrl("android")}>
                    <img
                      src={
                        require("../../assets/newimg/andriod_logo.png").default
                      }
                      alt=""
                    />
                    <div className="text">
                      <h6 className="name">Android APK</h6>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div
            className="self"
            // data-aos="fade-in"
            // data-aos-delay="0"
            // data-aos-duration="0"
            // data-aos-easing="ease-in-out"
          >
            <div className="content">
              <div className="left">
                <div className="phone">
                  <img
                    src={require("../../assets/home/bg.png").default}
                    alt=""
                    width={"100%"}
                  />
                  <img
                    className="contentImg"
                    src={require("../../assets/home/phone.png").default}
                    alt=""
                    width={"100%"}
                    // data-aos="zoom-in-out"
                  />
                </div>
                <div
                  className="left-avatar card"
                  data-aos="zoom-in-up"
                  data-aos-offset="200"
                  data-aos-delay="250"
                  data-aos-duration="1500"
                  data-aos-easing="ease-in-out"
                  data-aos-mirror="true"
                  data-aos-anchor-placement="bottom-bottom"
                >
                  <img
                    src={require("../../assets/home/hudie.png").default}
                    alt=""
                    width={"100%"}
                  />
                </div>
                <div
                  className="left-xing card"
                  data-aos="zoom-in-up"
                  data-aos-offset="200"
                  data-aos-delay="60"
                  data-aos-duration="1500"
                  data-aos-easing="ease-in-out"
                  data-aos-mirror="true"
                  data-aos-anchor-placement="bottom-bottom"
                >
                  <img
                    src={require("../../assets/home/xing.png").default}
                    alt=""
                    width={"100%"}
                  />
                </div>
                <div
                  className="right-avatar card"
                  data-aos="zoom-in-up"
                  data-aos-offset="-200"
                  data-aos-delay="50"
                  data-aos-duration="1000"
                  data-aos-easing="ease-in-out"
                  data-aos-mirror="true"
                  data-aos-anchor-placement="bottom-bottom"
                >
                  <img
                    src={require("../../assets/home/face.png").default}
                    alt=""
                    width={"100%"}
                  />
                </div>
              </div>
              <div className="right">
                <h1
                  className="title card"
                  data-aos="fade-up"
                  data-aos-offset="200"
                  data-aos-delay="50"
                  data-aos-duration="1000"
                  data-aos-easing="ease-in-out"
                  data-aos-mirror="true"
                  data-aos-anchor-placement="top-center"
                >
                  {" "}
                  Know yourself{" "}
                </h1>
                <p
                  className="card"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-easing="ease-in-out"
                  data-aos-mirror="true"
                  data-aos-anchor-placement="top-center"
                >
                  Do a "soul test", and get your web3 "soulbound token" (SBT).
                  It reveals a lot about who you are and what you love.
                </p>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div
            className="life"
            // data-aos="fade-in"
            // data-aos-delay="0"
            // data-aos-duration="0"
            // data-aos-easing="ease-in-out"
          >
            <div className="content">
              <div className="right">
                <div className="phone">
                  <img
                    src={require("../../assets/home/bg.png").default}
                    alt=""
                    width={"100%"}
                  />
                  <img
                    className="contentImg"
                    src={require("../../assets/home/crushphone.png").default}
                    alt=""
                    width={"100%"}
                    // data-aos="zoom-in-out"
                  />
                </div>
              </div>
              <div className="left">
                <div className="text">
                  <h1
                    className="title card"
                    data-aos="fade-up"
                    data-aos-offset="200"
                    data-aos-delay="50"
                    data-aos-duration="1000"
                    data-aos-easing="ease-in-out"
                    data-aos-mirror="true"
                    data-aos-anchor-placement="bottom-bottom"
                  >
                    Soul-based algorithm
                  </h1>
                  <p
                    className="card"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-easing="ease-in-out"
                    data-aos-mirror="true"
                    data-aos-anchor-placement="top-center"
                  >
                    We recommend people that get your personality traits and
                    share your interest through our SBT matching system.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div
            className="swiping"
            // data-aos="fade-in"
            // data-aos-delay="0"
            // data-aos-duration="0"
            // data-aos-easing="ease-in-out"
          >
            <div className="content">
              <div className="left">
                <div className="phone">
                  <img
                    src={require("../../assets/home/bg.png").default}
                    alt=""
                    width={"100%"}
                  />
                  <img
                    className="contentImg"
                    src={require("../../assets/home/swipingPhone.png").default}
                    alt=""
                    width={"100%"}
                    // data-aos="zoom-in-out"
                  />
                </div>
                <div
                  className="left-avatar card"
                  data-aos="zoom-in-up"
                  data-aos-offset="200"
                  data-aos-delay="250"
                  data-aos-duration="1500"
                  data-aos-easing="ease-in-out"
                  data-aos-mirror="true"
                  data-aos-anchor-placement="bottom-bottom"
                >
                  <img
                    src={require("../../assets/home/aixin.png").default}
                    alt=""
                    width={"100%"}
                  />
                </div>
                <div
                  className="left-xing card"
                  data-aos="zoom-in-up"
                  data-aos-offset="200"
                  data-aos-delay="60"
                  data-aos-duration="1500"
                  data-aos-easing="ease-in-out"
                  data-aos-mirror="true"
                  data-aos-anchor-placement="bottom-bottom"
                >
                  <img
                    src={require("../../assets/home/huaxue.png").default}
                    alt=""
                    width={"100%"}
                  />
                </div>
                <div
                  className="right-avatar card"
                  data-aos="zoom-in-up"
                  data-aos-offset="-200"
                  data-aos-delay="50"
                  data-aos-duration="1000"
                  data-aos-easing="ease-in-out"
                  data-aos-mirror="true"
                  data-aos-anchor-placement="bottom-bottom"
                >
                  <img
                    src={require("../../assets/home/tag.png").default}
                    alt=""
                    width={"100%"}
                  />
                </div>
              </div>
              <div className="right">
                <h1
                  className="title card"
                  data-aos="fade-up"
                  data-aos-offset="200"
                  data-aos-delay="50"
                  data-aos-duration="1000"
                  data-aos-easing="ease-in-out"
                  data-aos-mirror="true"
                  data-aos-anchor-placement="top-center"
                >
                  {" "}
                  No more swiping{" "}
                </h1>
                <p
                  className="card"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-easing="ease-in-out"
                  data-aos-mirror="true"
                  data-aos-anchor-placement="top-center"
                >
                  Explore and jump into anyone's universe right away! Find your
                  real crush and connect instantly.
                </p>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div
            className="game"
            // data-aos="fade-in"
            // data-aos-delay="0"
            // data-aos-duration="0"
            // data-aos-easing="ease-in-out"
          >
            <div className="content">
              <div className="right">
                <div className="phone">
                  <img
                    src={require("../../assets/home/bg.png").default}
                    alt=""
                    width={"100%"}
                  />
                  <img
                    className="contentImg"
                    src={require("../../assets/home/gamePhone.png").default}
                    alt=""
                    width={"100%"}
                    // data-aos="zoom-in-out"
                  />
                </div>
              </div>
              <div className="left">
                <div className="text">
                  <h1
                    className="title card"
                    data-aos="fade-up"
                    data-aos-offset="200"
                    data-aos-delay="50"
                    data-aos-duration="1000"
                    data-aos-easing="ease-in-out"
                    data-aos-mirror="true"
                    data-aos-anchor-placement="bottom-bottom"
                  >
                    Gamified experience
                  </h1>
                  <p
                    className="card"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-easing="ease-in-out"
                    data-aos-mirror="true"
                    data-aos-anchor-placement="top-center"
                  >
                    There are sooo many ways to meet new people: mysterious
                    phonecalls, virtual parties, anonymous meetups, NFT breeding
                    games... you name it!{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div
            className="yourself"
            // data-aos="fade-in"
            // data-aos-delay="0"
            // data-aos-duration="0"
            // data-aos-easing="ease-in-out"
          >
            <div className="content">
              <div className="left">
                <div className="phone">
                  <img
                    src={require("../../assets/home/bg.png").default}
                    alt=""
                    width={"100%"}
                  />
                  <img
                    className="contentImg"
                    src={require("../../assets/home/Soulbou.png").default}
                    alt=""
                    width={"100%"}
                    // data-aos="zoom-in-out"
                  />
                </div>
                <div
                  className="left-avatar card"
                  data-aos="zoom-in-up"
                  data-aos-offset="200"
                  data-aos-delay="250"
                  data-aos-duration="1500"
                  data-aos-easing="ease-in-out"
                  data-aos-mirror="true"
                  data-aos-anchor-placement="bottom-bottom"
                >
                  <img
                    src={require("../../assets/home/xingai.png").default}
                    alt=""
                    width={"100%"}
                  />
                </div>
                <div
                  className="right-xing card"
                  data-aos="zoom-in-up"
                  data-aos-offset="200"
                  data-aos-delay="60"
                  data-aos-duration="1500"
                  data-aos-easing="ease-in-out"
                  data-aos-mirror="true"
                  data-aos-anchor-placement="bottom-bottom"
                >
                  <img
                    src={require("../../assets/home/tangguo.png").default}
                    alt=""
                    width={"100%"}
                  />
                </div>
                <div
                  className="right-avatar card"
                  data-aos="zoom-in-up"
                  data-aos-offset="-200"
                  data-aos-delay="50"
                  data-aos-duration="1000"
                  data-aos-easing="ease-in-out"
                  data-aos-mirror="true"
                  data-aos-anchor-placement="bottom-bottom"
                >
                  <img
                    src={require("../../assets/home/xingxing.png").default}
                    alt=""
                    width={"100%"}
                  />
                </div>
              </div>
              <div className="right">
                <h1
                  className="title card"
                  data-aos="fade-up"
                  data-aos-offset="200"
                  data-aos-delay="50"
                  data-aos-duration="1000"
                  data-aos-easing="ease-in-out"
                  data-aos-mirror="true"
                  data-aos-anchor-placement="top-center"
                >
                  Be yourself, and find the like-minded{" "}
                </h1>
                <p
                  className="card"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-easing="ease-in-out"
                  data-aos-mirror="true"
                  data-aos-anchor-placement="top-center"
                >
                  Tired of being a poser on social media? Share your real
                  lifestyle here anonymously and safely. That soul who really
                  gets you will come around!
                </p>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div
            className="nft"
            // data-aos="fade-in"
            // data-aos-delay="0"
            // data-aos-duration="0"
            // data-aos-easing="ease-in-out"
          >
            <div className="content" id="NFT">
              <div className="right">
                <div className="img_box">
                  <div className="card" data-aos="zoom-in-out">
                    <img
                      src={require("../../assets/home/NFT.png").default}
                      alt=""
                      width={"100%"}
                    />
                  </div>
                </div>
              </div>
              <div className="left">
                <h1
                  className="title card"
                  data-aos="fade-up"
                  data-aos-offset="200"
                  data-aos-delay="50"
                  data-aos-duration="1000"
                  data-aos-easing="ease-in-out"
                  data-aos-mirror="true"
                  data-aos-anchor-placement="bottom-bottom"
                >
                  Find your Crushe NFT <br /> Get your Crushe superpower
                  <br /> Be yourself
                </h1>
                <ul className="list">
                  <div
                    className="item card"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-easing="ease-in-out"
                    data-aos-mirror="true"
                    data-aos-anchor-placement="top-center"
                  >
                    <span>.</span>
                    <p> Find the Crushe NFTs that match your soul the most.</p>
                  </div>
                  <div
                    className="item card"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-easing="ease-in-out"
                    data-aos-mirror="true"
                    data-aos-anchor-placement="top-center"
                  >
                    <span>.</span>
                    <p>
                      {" "}
                      It speeds up your level in Crushe, and increases your
                      chance of getting free airdrops.{" "}
                    </p>
                  </div>
                  <div
                    className="item card"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-easing="ease-in-out"
                    data-aos-mirror="true"
                    data-aos-anchor-placement="top-center"
                  >
                    <span>.</span>
                    <p>
                      {" "}
                      If you find your soulmate in Crushe, you two can breed
                      your NFT offspring.
                    </p>
                  </div>
                </ul>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="footer-box">
            <div className="token">
              <div className="content" id="Token">
                <h1
                  className="title card"
                  data-aos="fade-up"
                  data-aos-offset="200"
                  data-aos-delay="50"
                  data-aos-duration="1000"
                  data-aos-easing="ease-in-out"
                  data-aos-mirror="true"
                  data-aos-anchor-placement="bottom-bottom"
                >
                  Token
                </h1>
                <ul className="list">
                  <li
                    className="item item1 card"
                    data-aos="zoom-in-up"
                    data-aos-offset="-200"
                    data-aos-delay="50"
                    data-aos-duration="1000"
                    data-aos-easing="ease-in-out"
                    data-aos-mirror="true"
                    data-aos-anchor-placement="bottom-bottom"
                  >
                    {/* <h6 className="title">$SPARK</h6> */}
                    <div className="coin_box">
                      <img
                        src={require("../../assets/newimg/token1.png").default}
                        alt=""
                        width={"100%"}
                      />
                    </div>
                    <p>Spark</p>
                  </li>
                  <li
                    className="item item2 card"
                    data-aos="zoom-in-up"
                    data-aos-offset="-200"
                    data-aos-delay="50"
                    data-aos-duration="1000"
                    data-aos-easing="ease-in-out"
                    data-aos-mirror="true"
                    data-aos-anchor-placement="bottom-bottom"
                  >
                    <div className="coin_box">
                      <img
                        src={require("../../assets/newimg/token2.png").default}
                        alt=""
                        width={"100%"}
                      />
                    </div>
                    <p>Heart</p>
                  </li>
                  <li
                    className="item item3 card"
                    data-aos="zoom-in-up"
                    data-aos-offset="-200"
                    data-aos-delay="50"
                    data-aos-duration="1000"
                    data-aos-easing="ease-in-out"
                    data-aos-mirror="true"
                    data-aos-anchor-placement="bottom-bottom"
                  >
                    <div className="coin_box">
                      <img
                        src={require("../../assets/newimg/token3.png").default}
                        alt=""
                        width={"100%"}
                      />
                    </div>
                    <p>Crushe</p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="footer">
              <div className="content">
                <div className="left">
                  <div className="nav">
                    <a href="/privacy" target={"_blank"}>
                      Privacy policy
                    </a>
                    <span className="shu"></span>
                    <a href="/terms" target={"_blank"}>
                      Terms of service
                    </a>
                    <span className="shu"></span>
                    <span>Email: nxgentech2022@gmail.com</span>
                  </div>
                </div>
                <div className="right">
                  © 2022 NXG Labs. All rights reserved.
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  );
};

export default SwiperDemo;
